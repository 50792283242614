import { useState, useEffect, useContext } from "react";
import { MultiSelect } from "../../../components/menu/multiSelect";
import { AuthContext } from "../../../contexts/AuthContext";

function ReportingCustomerFilter({
  selected,
  onUpdate,
  onClear,
  fullWidth = false,
  labelPosition = "inset",
}) {
  const [customerOptions, setCustomerOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { authContext } = useContext(AuthContext);
  const {
    organization: { orgId },
    user: { userId, accessLevel },
  } = authContext;
  const fetchCustomers = async () => {
    if (!orgId || !accessLevel) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/customers?${orgId}?user_id=${userId}&access_level=${accessLevel}`
      );
      if (response.ok) {
        const data = await response.json();

        const augmentedData = data.map((customer) => ({
          ...customer,
          name: customer.customer_name,
        }));

        setCustomerOptions(augmentedData);
        setIsLoading(false);
      } else {
        console.log("Error fetching companies");
      }
    } catch (error) {
      console.log("Error fetching companies");
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, [orgId, accessLevel]);

  return (
    <MultiSelect
      label="Customers"
      options={customerOptions}
      onUpdate={onUpdate}
      onClear={onClear}
      selected={selected}
      isLoading={isLoading}
      fullWidth={fullWidth}
      labelPosition={labelPosition}
    />
  );
}

export { ReportingCustomerFilter };
