// topBar.js (simplified snippet)
import React, { useContext, Fragment } from "react";
import { UserProfile } from "../components/profile";
import { Bars3Icon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import classNames from "../utils/classNames";
import { AuthContext } from "../contexts/AuthContext";

export default function TopBar({
  subHeading,
  heading,
  userNavigation,
  setSidebarOpen,
}) {
  const { authContext } = useContext(AuthContext);
  return (
    <div className="z-40 flex h-16 items-center border-b border-gray-100 bg-white lg:py-10 px-6">
      <button
        type="button"
        className="m-2.5 p-2.5 text-gray-700 lg:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
      </button>

      <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
        <div className="relative flex flex-1 items-center">
          <div>
            {subHeading ? (
              <p className="text-xsm font-normal text-gray-500">{subHeading}</p>
            ) : null}
            <h1 className="text-2xl font-bold">{heading || "Heading"}</h1>
          </div>
        </div>

        <div className="flex items-center gap-x-4 lg:gap-x-2">
          <Menu as="div" className="relative">
            <Menu.Button className="flex items-center text-gray-400 hover:text-gray-700 duration-200 transition-colors group">
              <span className="sr-only">Open user menu</span>
              <div className="flex items-center">
                <UserProfile
                  imageUrl={authContext.user.imageUrl}
                  size="small"
                  firstName={authContext.user.firstName}
                  lastName={authContext.user.lastName}
                  useInitials={!authContext.user.imageUrl}
                  className="group-hover:bg-gray-50 group-hover:text-gray-800"
                />
                <span className="hidden lg:flex lg:items-center">
                  <ChevronDownIcon
                    className="h-3 w-3 ml-1"
                    aria-hidden="true"
                  />
                </span>
              </div>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-black/5 focus:outline-none">
                {userNavigation.map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <>
                        {item.type === "link" && (
                          <Link
                            to={item.href}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-3 py-1 text-sm leading-6 text-black"
                            )}
                          >
                            {item.name}
                          </Link>
                        )}
                        {item.type === "function" && (
                          <button
                            onClick={item.onClick}
                            className={classNames(
                              active ? "bg-gray-100" : "",
                              "block px-3 py-1 text-sm leading-6 text-black"
                            )}
                          >
                            {item.name}
                          </button>
                        )}
                      </>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
}
