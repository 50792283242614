import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import logo from "../assets/logo-mark.svg";
import classNames from "../utils/classNames";

export default function DesktopNavigation({ navOptions, orgName }) {
  const [expanded, setExpanded] = useState(null);

  const handleToggle = (itemName) => {
    setExpanded((prev) => (prev === itemName ? null : itemName));
  };

  return (
    <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col bg-gray-25 border-gray-100 border-r">
      <div className="flex flex-col h-full">
        <div className="flex items-center pt-8 pl-6 pb-9">
          <Link to="/">
            <img className="h-6 w-auto" src={logo} alt="Opspot" />
          </Link>
        </div>

        {/* Main navigation */}
        <nav className="flex-1">
          <ul role="list" className="flex flex-col">
            {navOptions.map((item) => {
              const hasChildren = item.children && item.children.length > 0;
              const isExpanded = expanded === item.name;

              return (
                <React.Fragment key={item.name}>
                  <li
                    className={`${
                      item.separator ? "border-t border-gray-100 mt-4 pt-6" : ""
                    } flex items-center mx-4`}
                  >
                    {hasChildren ? (
                      <button
                        type="button"
                        onClick={() => handleToggle(item.name)}
                        className={classNames(
                          item.current && !hasChildren
                            ? "text-brand-primary font-semibold hover:bg-gray-50"
                            : "font-medium text-gray-600 hover:text-gray-700 hover:bg-gray-50 transition-colors duration-200",
                          "group flex items-center mb-1 gap-x-3 p-2 rounded-md text-sm leading-6 tracking-wide w-full justify-between"
                        )}
                      >
                        <div className="flex items-center gap-x-3">
                          {item.icon && (
                            <item.icon
                              className="h-5 w-5 shrink-0 text-gray-700 hover:text-gray-800 transition-colors duration-200"
                              aria-hidden="true"
                            />
                          )}
                          <span>{item.name}</span>
                        </div>
                        {isExpanded ? (
                          <ChevronUpIcon className="h-3 w-3 text-gray-600" />
                        ) : (
                          <ChevronDownIcon className="h-3 w-3 text-gray-600" />
                        )}
                      </button>
                    ) : (
                      <Link
                        to={item.href}
                        className={classNames(
                          item.current && !hasChildren
                            ? "text-brand-primary font-semibold hover:bg-gray-50"
                            : "font-medium text-gray-600 hover:text-gray-700 hover:bg-gray-50 transition-colors duration-200",
                          "group flex items-center mb-1 gap-x-3 p-2 rounded-md text-sm leading-6 tracking-wide w-full"
                        )}
                      >
                        {item.icon && (
                          <item.icon
                            className="h-5 w-5 shrink-0 text-gray-700 hover:text-gray-800 transition-colors duration-200"
                            aria-hidden="true"
                          />
                        )}
                        <span>{item.name}</span>
                      </Link>
                    )}
                  </li>

                  {hasChildren && (
                    <ul
                      className={classNames(
                        "overflow-hidden transition-all duration-200 mb-2 -mt-2",
                        isExpanded ? "max-h-40" : "max-h-0"
                      )}
                    >
                      {item.children.map((child) => (
                        <li key={child.name}>
                          <Link
                            to={child.href}
                            className={classNames(
                              child.current
                                ? "text-brand-primary text-sm font-semibold"
                                : "text-sm text-gray-600 hover:text-gray-700 transition-colors duration-200",
                              "ml-4 mr-4 pl-10 rounded-md block py-2 hover:bg-gray-50"
                            )}
                          >
                            {child.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </React.Fragment>
              );
            })}
          </ul>
        </nav>
      </div>
    </div>
  );
}
