import React, { useEffect, useState, useContext } from "react";
// import { Tabs } from "../../../components/tabs";
import { TextInput } from "../../../components/textInput";
import { ImageInput } from "../../../components/imageInput";
import { Toast } from "../../../components/toast";
import { Button } from "../../../components/button";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { AuthContext } from "../../../contexts/AuthContext";
import logo from "../../../assets/logo-mark.svg";
import bgImage from "../../../assets/bg/simple-opaque.png";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

const schema = z.object({
  name: z.string().min(1, "Name is required"),
});

function Organization() {
  const { getToken, fetchUserData, authContext } = useContext(AuthContext);
  const orgId = authContext.organization.orgId;
  const initializationComplete =
    authContext.organization.initializationComplete;
  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState(true);
  const [backendData, setBackendData] = useState({
    name: "",
    logo_url: "",
    initialization_complete: 0,
  });
  const [priceId, setPriceId] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(true);

  setTimeout(() => {
    setIsPageLoading(false);
  }, 1200);

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      logo_url: "",
    },
    resolver: zodResolver(schema),
  });

  const { handleSubmit, setValue } = methods;

  /// Toast ///
  const [toast, setToast] = useState({ show: false, message: "", type: "" });
  const handleResponse = (message, type) => {
    setToast({ show: true, message, type });
    if (backendData.initialization_complete === 0) {
      setTimeout(() => {
        navigate("/");
      });
    }
  };

  const hideToast = () => {
    setToast((prev) => ({ ...prev, show: false }));
  };

  const onSubmit = (data) => {
    return updateOrganization(data);
  };

  /// Handle success ///
  const onSuccess = async () => {
    console.log("Organization details updated successfully");
    try {
      await fetchUserData();
      console.log("fetchUserData completed successfully");
    } catch (error) {
      console.error("Error in fetchUserData:", error);
    }
    handleResponse("Organization details updated successfully", "success");
    if (localStorage.getItem("priceId")) {
      localStorage.removeItem("priceId");
    }
  };

  /// Images ///
  const [imageFile, setImageFile] = useState(null);

  /// Fetch organization details ///
  const fetchOrganization = () => {
    if (!orgId) {
      return;
    }

    fetch(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}`)
      .then((response) => {
        if (!response.ok)
          throw new Error(`Fetch returned status ${response.status}`);
        return response.json();
      })
      .then((data) => {
        setBackendData({
          name: data.name,
          logo_url: data.logo_url,
          initialization_complete: data.initialization_complete,
          stripe_customer_id: data.stripe_customer_id,
        });
        setValue("name", data.name);
        setIsFetching(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchOrganization();
  }, [orgId]);

  // useEffect(() => {
  //   if (isFetching === false && backendData.initialization_complete === 0) {
  //     setIsInitializing(true);
  //   }
  // }, [isFetching, backendData]);

  /// Update user ///
  function updateOrganization(data) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key !== "customers") {
        formData.append(key, data[key]);
      }
    });

    if (imageFile) {
      formData.append("file", imageFile);
    } else if (backendData.logo_url) {
      formData.append("logo", backendData.logo_url);
    }

    if (priceId) {
      formData.append("price_id", priceId);
    }

    if (backendData.stripe_customer_id) {
      formData.append("stripe_customer_id", backendData.stripe_customer_id);
    }

    const url = !initializationComplete
      ? `${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}?initializing=true`
      : `${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}`;

    console.log("URL", url);
    console.log("Initializing", initializationComplete);

    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok)
          throw new Error(`Fetch returned status ${response.status}`);
        return response.json();
      })
      .then(() => {
        onSuccess();
        handleResponse("Organization details updated successfully", "success");
      });
  }

  const onError = (errors, event) => {
    console.log("form error");
    console.log(errors);
  };

  /// Image upload ///
  function handleImageChange(event) {
    const file = event.target.files[0];
    if (!file) {
      console.log("No file selected.");
      return;
    }

    setImageFile(file);
  }

  /// Get and set the price id passed via registration ///
  useEffect(() => {
    // Check if we're done initializing and the price is available
    if (!initializationComplete) {
      const storedPriceId = localStorage.getItem("selectedPrice");
      if (storedPriceId) {
        console.log("Initializing with price id:", storedPriceId);
        setPriceId(storedPriceId);
      } else {
        setPriceId(process.env.REACT_APP_STARTER_PLAN);
      }
    }
  }, [initializationComplete]); // Only run this effect when `initializationComplete` changes

  return !isFetching ? (
    <div
      className={` ${
        !initializationComplete
          ? "relative bg-no-repeat h-screen bg-left-bottom"
          : ""
      }`}
      style={
        !initializationComplete
          ? {
              backgroundImage: `url(${bgImage})`,
              backgroundSize: "25%", // Adjust size of the image (50% of its original size)
              backgroundPosition: "calc(100% - 10px) calc(100% + 200px)",
            }
          : {}
      }
    >
      {/* {initializationComplete && (
        <>
          <Tabs current="Organization" section="settings" />
        </>
      )} */}

      {/* <Tabs current="Activity" section="reporting" /> */}
      <main className="py-6">
        {!initializationComplete && (
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="flex items-center">
              <div className="flex-1 py-6">
                <div className="space-y-6 w-1/3">
                  <div className="mt-16">
                    <img src={logo} alt="Opspot" className="w-12" />
                  </div>
                  <p className="text-sm text-gray-500">
                    Welcome to Opspot! What can we call you?
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex items-center mb-4">
            <FormProvider {...methods}>
              <div className="flex-1 py-6">
                <div className="space-y-12 w-1/3">
                  <TextInput name="name" label="Organization name" />
                  <ImageInput
                    name="logo"
                    label="Logo (optional)"
                    onChange={handleImageChange}
                    accept="image/png, image/jpeg, image/gif, image/svg+xml, image/webp"
                    maxFileSize={3201010}
                    existing={backendData.logo_url}
                  />
                  <Button
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    className="w-full"
                    label="Save"
                  />
                </div>
              </div>
            </FormProvider>

            <Toast
              show={toast.show}
              message={toast.message}
              type={toast.type}
              onHide={hideToast}
            />
          </div>
        </div>
      </main>
    </div>
  ) : null;
}

export { Organization };
