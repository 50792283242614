import React, { useEffect, useState, useContext } from "react";
import { DeleteConfirm } from "../../../components/modal/deleteConfirm";
import { TrashIcon } from "@heroicons/react/24/outline";
import { LinkButton } from "../../../components/button/link";
import { SuccessToast } from "../../../components/toast/success";
import { AuthContext } from "../../../contexts/AuthContext";
import { UserDetails } from "./details";
import { Table } from "../../../components/table";
import { TableLoadingAnimation } from "../../../components/loading/tableLoading";

function Users() {
  const { authContext } = useContext(AuthContext);
  const orgId = authContext.organization.orgId;
  const allowedGuards = authContext.organization.planFeatures.Guards;
  const [guardCount, setGuardCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState({
    identifier: "",
    id: "",
    secondaryId: "",
  });
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [isAddMode, setIsAddMode] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  const toggleState = (setState, state) => {
    setState(!state);
  };

  const handleDeleteClick = (identifier, id, secondaryId) => {
    setSelectedDeleteItem({ identifier, id, secondaryId });
    toggleState(setIsDeleteOpen, isDeleteOpen);
  };

  const handleAddMode = (id) => {
    setSelectedUserId(id);
    setIsAddMode(false);
    toggleState(setIsDetailsOpen, isDetailsOpen);
  };

  /// Delete user ///
  const deleteUser = (userId, secondaryId) => {
    console.log("Deleting user with ID:", userId);
    console.log("Secondary ID:", secondaryId);
    return fetch(`${process.env.REACT_APP_API_URL}/v1/users/delete/${userId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        auth_id: secondaryId,
      }),
    }).then((response) => response.json());
  };

  const [deleteSuccess, setDeleteSuccess] = useState({
    displaying: false,
    message: "User has been deleted",
  });

  const handleDeleteSuccess = () => {
    fetchUsers();

    setDeleteSuccess({
      displaying: true,
      message: "User has been deleted",
    });
    setTimeout(() => {
      setDeleteSuccess({ displaying: false, message: deleteSuccess.message });
    }, 3000);
  };

  // const [fetchFlag, setFetchFlag] = useState(false);

  // const handleSuccess = () => {
  //   setFetchFlag(!fetchFlag);
  // };
  const handleSuccess = async () => {
    setIsLoading(true); // Show loading spinner
    await fetchUsers(); // Re-fetch users after save is complete
  };

  useEffect(() => {
    if (orgId) {
      setIsLoading(true);
      fetchUsers();
    }
  }, [orgId]);

  // useEffect(() => {
  //   if (orgId) {
  //     fetchUsers();
  //   }
  // }, [fetchFlag]);

  /// Fetch users ///
  const [backendData, setBackendData] = useState([]);
  const fetchUsers = async () => {
    if (!orgId) return;
    console.log("Fetching users for org ID:", orgId);

    const fetchPromise = fetch(
      `${process.env.REACT_APP_API_URL}/v1/users?org_id=${orgId}`
    )
      .then(async (response) => {
        if (response.ok) {
          const data = await response.json();
          setBackendData(data.users); // Update state with the fetched data
          setGuardCount(data.guardCount);
          console.log("Fetched users:", data);
        } else {
          // Handle HTTP errors e.g., 404, 500 etc.
          console.error("HTTP Error: ", response.statusText);
          // Optionally, update state to display an error message
        }
      })
      .catch((error) => {
        // Handle network errors, parsing errors, etc.
        console.error("Fetching error: ", error.message);
        // Optionally, update state to display an error message
      });

    const timerPromise = new Promise((resolve) => setTimeout(resolve, 1200));

    Promise.all([fetchPromise, timerPromise]).then(() => {
      setIsLoading(false);
    });
  };

  /// Table columns ///
  const columns = React.useMemo(
    () => [
      {
        Header: "User",
        accessor: "user_name",
        nowrap: true,
        Cell: ({ row }) => (
          <div>
            {row.original.profile_url && row.original.profile_url.length > 0 ? (
              <img
                src={row.original.profile_url}
                alt=""
                className="mr-3 h-10 w-10 rounded-full object-contain bg-white inline-block align-middle"
              />
            ) : (
              <span className="mr-2 w-10 h-10 rounded-full bg-gray-200 inline-block align-middle"></span>
            )}
            <a
              onClick={() => handleAddMode(row.original.id)}
              className="text-gray-500 hover:text-gray-600 hover:underline capitalize cursor-pointer"
            >
              {row.original.first_name} {row.original.last_name}
            </a>
          </div>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Customers",
        accessor: "customer_names",
        width: 100,
      },
      {
        Header: "Permissions",
        accessor: "roles",
        Cell: ({ row }) => {
          const roles = row.original.roles || [];

          // Map roles to display-friendly labels
          const roleLabels = roles.map((role) => {
            if (role === "admin:access") return "Admin";
            if (role === "guard:access") return "Guard";
            return null; // Skip unknown roles
          });

          // Filter out nulls and join roles with a comma for display
          return roleLabels.filter(Boolean).join(", ") || "User";
        },
      },
      {
        Header: "",
        id: "delete",
        accessor: (str) => "delete",
        Cell: ({ row }) => (
          <button
            className={`pl-6 py-4 block ${
              row.original.is_admin === 1
                ? "cursor-not-allowed text-gray-300 hover:text-gray-300"
                : " text-gray-600 hover:text-gray-500 hover:underline"
            }`}
            onClick={() =>
              handleDeleteClick(
                row.original.first_name + " " + row.original.last_name,
                row.original.id,
                row.original.auth_id
              )
            }
            disabled={row.original.is_admin === 1}
          >
            <TrashIcon className="h-4 w-4 shrink-0" />
          </button>
        ),
      },
    ],
    []
  );

  /// Toggle side sheet ///
  const toggleDetailsOpen = () => {
    if (isDetailsOpen) {
      setSelectedUserId(null); // Reset selectedUserId when closing the sheet
    }
    setIsDetailsOpen(!isDetailsOpen);
  };

  return (
    <div>
      <main className="py-6">
        <div className="px-4 sm:px-6 lg:px-6">
          <div className="flex items-center justify-end mb-4">
            {/* <h2 className="text-xl bold">Users</h2> */}
            <div className="justify-between">
              <LinkButton
                type="function"
                text={`Create user${
                  allowedGuards > 0 && guardCount
                    ? ` (${guardCount}/${allowedGuards})`
                    : ""
                }`}
                onClick={() => {
                  setIsAddMode(true);
                  toggleState(setIsDetailsOpen, isDetailsOpen);
                }}
                // disabled={allowedGuards <= guardCount}
              />
            </div>
          </div>

          {isLoading ? (
            <TableLoadingAnimation isLoading={isLoading} />
          ) : backendData && backendData.length > 0 ? (
            <Table data={backendData} columns={columns} />
          ) : (
            <p>No results found.</p>
          )}

          {deleteSuccess.displaying && (
            <SuccessToast message={deleteSuccess.message} />
          )}
        </div>
      </main>
      <DeleteConfirm
        isOpen={isDeleteOpen}
        toggleOpen={() => toggleState(setIsDeleteOpen, isDeleteOpen)}
        selectedDeleteItem={selectedDeleteItem}
        handleDeleteSuccess={handleDeleteSuccess}
        onDelete={deleteUser}
      />
      <UserDetails
        isOpen={isDetailsOpen}
        toggleOpen={toggleDetailsOpen}
        selectedUserId={selectedUserId}
        isAddMode={isAddMode}
        onSuccess={handleSuccess}
        allowedGuards={allowedGuards}
        currentGuardCount={guardCount}
        updateGuardCount={(newCount) => setGuardCount(newCount)}
      />
    </div>
  );
}

export { Users };
