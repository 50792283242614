import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { XMarkIcon, BuildingOfficeIcon } from "@heroicons/react/24/outline";
import logo from "../assets/logo-mark.svg";
import classNames from "../utils/classNames"; // Utility function for conditional classes

export default function MobileNavigation({
  navOptions,
  sidebarOpen,
  setSidebarOpen,
  orgName,
}) {
  return (
    <Transition.Root show={sidebarOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50 lg:hidden"
        onClose={setSidebarOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/80" />
        </Transition.Child>
        <div className="fixed inset-0 flex">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <Dialog.Panel className="relative flex w-full max-w-xs flex-1 bg-gray-25">
              <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                <button
                  type="button"
                  className="-m-2.5 p-2.5"
                  onClick={() => setSidebarOpen(false)}
                >
                  <XMarkIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </button>
              </div>
              <div className="flex flex-col gap-y-5 overflow-y-auto px-6 pb-2">
                <div className="flex h-16 items-center pt-12">
                  <Link to="/">
                    <img className="h-6 w-auto" src={logo} alt="Opspot" />
                  </Link>
                </div>
                <div className="pt-5 flex items-center">
                  <BuildingOfficeIcon className="h-5 w-5" aria-hidden="true" />
                  <p className="pl-3">{orgName}</p>
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul role="list" className="flex flex-1 flex-col">
                    {navOptions.map((item) => (
                      <li
                        key={item.name}
                        className={classNames(
                          item.current && "border-l-2 border-brand-secondary"
                        )}
                      >
                        <Link
                          to={item.href}
                          className={classNames(
                            item.current
                              ? "text-white font-medium"
                              : "text-gray-300 hover:text-gray-200",
                            "group flex items-center gap-x-3 pl-4 p-2 text-sm leading-6"
                          )}
                        >
                          <item.icon
                            className="h-5 w-5 shrink-0"
                            aria-hidden="true"
                          />
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
