import React, { useState, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { FilterPlanel } from "./filterPanel";
import { Table } from "../../../components/table";
import { TableLoadingAnimation } from "../../../components/loading/tableLoading";
import emptyReport from "../../../assets/icons/empty-report.svg";

function Reports() {
  const { authContext } = useContext(AuthContext);
  const orgId = authContext.organization.orgId;

  // When the component mounts, no search has been run yet.
  const [hasRunSearch, setHasRunSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState([]);

  const constructApiURL = (filters) => {
    const {
      selectedCustomers,
      selectedLocations,
      selectedUsers,
      dateRange,
      reportType,
      // etc. if you have more
    } = filters;

    let baseUrl = `${process.env.REACT_APP_API_URL}/v1/reporting/reports?org_id=${orgId}`;

    if (selectedCustomers?.length > 0) {
      const customerIds = selectedCustomers.join(",");
      baseUrl += `&customer_id=${customerIds}`;
    }

    if (selectedLocations?.length > 0) {
      const locationIds = selectedLocations.join(",");
      baseUrl += `&location_ids=${locationIds}`;
    }

    if (selectedUsers?.length > 0) {
      const userIds = selectedUsers.join(",");
      baseUrl += `&user_ids=${userIds}`;
    }

    if (dateRange?.startDate) {
      baseUrl += `&start_date=${dateRange.startDate}`;
    }
    if (dateRange?.endDate) {
      baseUrl += `&end_date=${dateRange.endDate}`;
    }

    // For debug
    console.log("Constructed URL:", baseUrl);
    return baseUrl;
  };

  // Fetches the data from the API
  const fetchReportsData = async (url) => {
    if (!orgId) return;
    setIsLoading(true);

    try {
      const response = await fetch(url);
      if (response.ok) {
        const data = await response.json();
        setReportData(data);
      } else {
        console.error("HTTP Error: ", response.statusText);
      }
    } catch (error) {
      console.error("Fetching error: ", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // Called when the user runs filters from the side panel
  const handleRunFilters = (filters) => {
    // Mark that a search has been run so the initial message goes away.
    setHasRunSearch(true);
    const currentApiURL = constructApiURL(filters);
    fetchReportsData(currentApiURL);
  };

  return (
    <div className="flex flex-row flex-1">
      <div className="flex flex-row flex-1">
        <div className="flex flex-1 flex-col py-6 px-6">
          <div className="flex flex-1 border px-6 py-6 border-gray-100 rounded-lg">
            {!hasRunSearch ? (
              // Initial state: no filters run yet
              <div className="flex items-center justify-center w-full">
                <div>Select filters to view reports</div>
              </div>
            ) : isLoading ? (
              // Loading state
              <div className="flex items-center justify-center w-full">
                <TableLoadingAnimation isLoading={isLoading} />
              </div>
            ) : reportData.length > 0 ? (
              // Data is present
              <div>
                <Table data={reportData} />
              </div>
            ) : (
              // No data was returned
              <div className="flex flex-col items-center justify-center w-full">
                <img src={emptyReport} alt="No reports" className="w-36 h-36" />
                <p className="mt-2 text-gray-300 text-center text-md">
                  No reports for the selected filters
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="bg-gray-25 border-l border-gray-100">
        <FilterPlanel onUpdate={handleRunFilters} />
      </div>
    </div>
  );
}

export { Reports };
