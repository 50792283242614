import React from "react";
import { Link } from "react-router-dom";
import { ArrowSmallLeftIcon } from "@heroicons/react/24/outline";

function Crumbs(props) {
  return (
    <div className="">
      <div className="flex pl-6 pt-6">
        <Link
          to={props.previous}
          className="hover:text-brand-primary duration-150 ease-in-out transition-all"
        >
          <ArrowSmallLeftIcon
            className="h-5 w-5 shrink-0 inline"
            aria-hidden="true"
          />
          <span className="pl-2 text-brand-primary">{props.section}</span>
        </Link>
        <span className="px-3">/</span>
        <span className="font-medium">{props.active}</span>
      </div>
    </div>
  );
}

export { Crumbs };
