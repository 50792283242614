import { useState, useEffect, useContext } from "react";
import { MultiSelect } from "../../../components/menu/multiSelect";
import { AuthContext } from "../../../contexts/AuthContext";

function ReportingLocationFilter({
  selected,
  onUpdate,
  onClear,
  customerIds,
  setLocationsOptions,
  fullWidth = false,
  labelPosition = "inset",
}) {
  const [locationsOptions, setLocationsOptionsLocal] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { authContext } = useContext(AuthContext);
  const orgId = authContext.organization.orgId;

  const fetchLocations = async () => {
    if (!customerIds || !orgId) return;

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/locations?org_id=${orgId}&customer_ids=${customerIds}`
      );
      if (response.ok) {
        const data = await response.json();

        const augmentedData = data.map((location) => ({
          ...location,
          name: location.location_name,
        }));

        setLocationsOptionsLocal(augmentedData);
        setLocationsOptions(augmentedData);
        setIsLoading(false);
      } else {
        console.log("Error fetching locations");
      }
    } catch (error) {
      console.log("Error fetching locations");
    }
  };

  useEffect(() => {
    fetchLocations();
  }, [orgId, customerIds]);

  return (
    <MultiSelect
      label="Locations"
      options={locationsOptions}
      onUpdate={onUpdate}
      onClear={onClear}
      selected={selected}
      isLoading={isLoading}
      fullWidth={fullWidth}
      labelPosition={labelPosition}
    />
  );
}

export { ReportingLocationFilter };
