import { useState, useContext, Fragment } from "react";
import DesktopNavigation from "./DesktopNavigation";
import MobileNavigation from "./MobileNavigation";
import TopBar from "./TopBar";
import { Link } from "react-router-dom";

import { Menu, Transition } from "@headlessui/react";

import { AuthContext } from "../contexts/AuthContext";

function Navigation(props) {
  const { authContext, logout } = useContext(AuthContext);
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <MobileNavigation
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        orgName={authContext.organization.orgName}
      />
      <DesktopNavigation orgName={authContext.organization.orgName} />

      <TopBar />
    </>
  );
}

export default {
  Navigation,
  MobileNavigation,
  DesktopNavigation,
  TopBar,
};
