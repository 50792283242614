import React, { useState, useEffect } from "react";
import { ReportingLocationFilter } from "../filters/locationFilter";
import { ReportingUserFilter } from "../filters/userFilter";
import { ReportingCustomerFilter } from "../filters/customerFilter";
import { PrimaryButton } from "../../../components/button/primary";
import Datepicker from "react-tailwindcss-datepicker";

function FilterPlanel({ onUpdate }) {
  const [reportType, setReportType] = useState("activity");
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [locationsOptions, setLocationsOptions] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [dateRange, setDateRange] = useState({
    startDate: new Date().toISOString().split("T")[0],
    endDate: new Date().toISOString().split("T")[0],
  });

  /// Handler for "run" click ///
  const handleRunClick = () => {
    const filters = {
      reportType,
      selectedCustomers,
      selectedLocations,
      selectedUsers,
      dateRange,
    };

    // Tells parent: "here are my filters"
    onUpdate(filters);
  };

  /// Handler for report type change ///
  const handleReportTypeChange = (e) => {
    setReportType(e.target.value);
  };

  /// Customers dropdown ///
  const handleSelectedCustomers = (optionId) => {
    setSelectedCustomers((currentSelected) => {
      if (currentSelected.includes(optionId)) {
        return currentSelected.filter((item) => item !== optionId);
      } else {
        return [...currentSelected, optionId];
      }
    });
  };

  const clearSelectedCustomers = () => {
    setSelectedCustomers([]);
  };

  /// Locations dropdown ///
  const handleSelectedLocations = (optionId) => {
    setSelectedLocations((currentSelected) => {
      if (currentSelected.includes(optionId)) {
        return currentSelected.filter((item) => item !== optionId);
      } else {
        return [...currentSelected, optionId];
      }
    });
  };

  const clearSelectedLocations = () => {
    setSelectedLocations([]);
  };

  /// Users dropdown ///
  const handleSelectedUsers = (optionId) => {
    setSelectedUsers((currentSelected) => {
      if (currentSelected.includes(optionId)) {
        return currentSelected.filter((item) => item !== optionId);
      } else {
        return [...currentSelected, optionId];
      }
    });
  };

  const clearSelectedUsers = () => {
    setSelectedUsers([]);
  };

  /// Date picker ///
  const handleselectedDateRange = (selectedDateRange) => {
    setDateRange(selectedDateRange);
  };

  useEffect(() => {
    // Update the selected locations when selected customers change
    setSelectedLocations((currentSelectedLocations) =>
      currentSelectedLocations.filter((location) =>
        locationsOptions.some((option) => option.id === location)
      )
    );
  }, [selectedCustomers, locationsOptions]);

  return (
    <>
      <div className="flex flex-grow px-6 py-6">
        <div className="">
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-600 mb-1">
              Report type
            </label>
            <select
              className="h-9 w-64 inline-flex rounded-md px-3 -ml-1 mr-1 items-center text-sm leading-none tracking-normal font-normal text-gray-600 placeholder-gray-500 ring-1 ring-gray-100 hover:bg-gray-50 bg-gray-25 border-0 pr-10 focus:outline-none focus:ring-2 focus:ring-gray-100"
              name="reportType"
              id="reportType"
              value={reportType}
              onChange={handleReportTypeChange}
            >
              <option value="activity">Activity report</option>
              <option value="shift">Shift report</option>
              <option value="incident">Incident report</option>
            </select>
          </div>
          {/* Conditionally Render Activity Frequency Select */}
          {reportType === "activity" && (
            <div className="mb-6">
              <label
                className="block text-sm font-medium text-gray-600 mb-1"
                htmlFor="activityFrequency"
              >
                Activity Frequency
              </label>
              <select
                className="h-9 w-64 inline-flex rounded-md px-3 -ml-1 mr-1 items-center text-sm leading-none tracking-normal font-normal text-gray-600 placeholder-gray-500 ring-1 ring-gray-100 hover:bg-gray-50 bg-gray-25 border-0 pr-10 focus:outline-none focus:ring-2 focus:ring-gray-100"
                name="activityFrequency"
                id="activityFrequency"
              >
                <option value="">-- Select Frequency --</option>
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
              </select>
            </div>
          )}
          <div className="mb-6">
            <ReportingCustomerFilter
              selected={selectedCustomers}
              onUpdate={handleSelectedCustomers}
              onClear={clearSelectedCustomers}
              fullWidth={true}
              labelPosition="outset"
            />
          </div>
          <div className="mb-6">
            <ReportingLocationFilter
              selected={selectedLocations}
              onUpdate={handleSelectedLocations}
              onClear={clearSelectedLocations}
              customerIds={selectedCustomers}
              setLocationsOptions={setLocationsOptions}
              fullWidth={true}
              labelPosition="outset"
            />
          </div>
          {/* <div className="inline mr-4">
                <ReportingActivityTypeFilter
                  selected={selectedActivityTypes}
                  onUpdate={handleSelectedActivityTypes}
                  onClear={clearSelectedActivityTypes}
                />
              </div> */}
          <div className="mb-6">
            <ReportingUserFilter
              selected={selectedUsers}
              onUpdate={handleSelectedUsers}
              onClear={clearSelectedUsers}
              fullWidth={true}
              labelPosition={"outset"}
            />
          </div>
          <div className="mb-6">
            <label className="block text-sm font-medium text-gray-600 mb-1">
              Date
            </label>
            <Datepicker
              startFrom={new Date("2024-01-05")}
              showShortcuts={true}
              inputClassName={
                "h-9 w-64 inline-flex rounded-md px-3 -ml-1 mr-1 items-center text-sm leading-none tracking-normal font-normal text-gray-600 placeholder-gray-500 ring-1 ring-gray-100 hover:bg-gray-50 bg-gray-25 border-0 pr-10 focus:outline-none focus:ring-2 focus:ring-gray-100"
              }
              separator={"to"}
              value={dateRange}
              onChange={handleselectedDateRange}
            />
          </div>
        </div>
      </div>

      <div className="flex flex-shrink-0 px-4 py-4 bg-white">
        <PrimaryButton label="Run" onClick={handleRunClick} />
      </div>
    </>
  );
}

export { FilterPlanel };
