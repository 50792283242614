import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import SegmentedSwitch from "../../../components/segmentedSwitch";
import { PrimaryButton } from "../../../components/button/primary";
import { Toast } from "../../../components/toast";
import { StatusIndicator } from "../../../components/statusIndicator";
import { Banner } from "../../../components/banner";
import { LinkButton } from "../../../components/button/link";
import PaymentIcon from "../../../components/paymentIcon";
import FormatPrice from "../../../utils/FormatPrice";
import UpdatePaymentForm from "./updatePaymentForm";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

const intervalOptions = {
  optionOne: {
    text: "Monthly",
    value: "month",
  },
  optionTwo: {
    text: "Yearly",
    value: "year",
  },
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function Plan() {
  const { authContext } = useContext(AuthContext);

  const {
    organization: {
      orgId,
      stripeCustomerId,
      planStatus,
      planTrialEnd,
      planTrialEndDate,
      planId,
    },
  } = authContext;
  const [backendData, setBackendData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedInterval, setSelectedInterval] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState("");
  const [showUpdatePaymentForm, setShowUpdatePaymentForm] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);

  /// Toast ///
  const [toast, setToast] = useState({ show: false, message: "", type: "" });
  const handleResponse = (message, type) => {
    setToast({ show: true, message, type });
  };

  const hideToast = () => {
    setToast((prev) => ({ ...prev, show: false }));
  };

  /// Change Subscription ///
  const handleChangeSubscription = async (priceId) => {
    try {
      setIsLoading(true);
      const stripe = await stripePromise;

      // Call the backend endpoint to change the subscription
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/subscription/change`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            priceId,
            customerId: stripeCustomerId,
            subscriptionId: planId,
            planTrialEnd: planTrialEnd,
            planStatus: planStatus,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to change subscription");
      }

      const result = await response.json();

      if (result.sessionId) {
        // Redirect to Stripe Checkout for upgrades
        const { error } = await stripe.redirectToCheckout({
          sessionId: result.sessionId,
        });
        if (error) {
          console.error("Stripe Checkout error:", error);
          handleResponse("Failed to redirect to Stripe Checkout", "error");
        }
      } else {
        // Handle downgrades or trial switches
        console.log("Subscription change successful:", result.message);
        await fetchProductsAndSubscription(); // Refresh subscription data
        handleResponse(result.message, "success");
      }
    } catch (error) {
      console.error("Error changing subscription:", error);
      handleResponse(
        "An error occurred while changing the subscription",
        "error"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleIntervalChange = (interval) => {
    setSelectedInterval(interval);
  };

  /// Fetch Products and Subscription ///
  const fetchProductsAndSubscription = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/subscription?stripeId=${stripeCustomerId}`
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      setBackendData({ ...data, products: data.products.reverse() });
      setSelectedInterval(getSelectedIntervalFromData(data));
      setPaymentInfo(data.paymentInfo);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching products and subscription:", error);
      setIsLoading(false);
    }
  };

  /// Create Setup Intent to update payment method ///
  const initiatePaymentUpdate = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/subscription/setup-payment`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ customerId: stripeCustomerId }),
      }
    );

    const result = await response.json();
    if (result.clientSecret) {
      setClientSecret(result.clientSecret);
      setShowUpdatePaymentForm(true);
    } else {
      console.error("Failed to create setup intent");
    }
  };

  /// Finalize Payment Method ///
  const finalizePaymentUpdate = async (paymentMethodId) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}/subscription/finalize-payment`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          customerId: stripeCustomerId,
          paymentMethodId,
        }),
      }
    );

    const result = await response.json();
    if (result.success) {
      setShowUpdatePaymentForm(false);
      setClientSecret(null);
      await fetchProductsAndSubscription(); // Refresh to show updated card
      handleResponse("Payment method updated successfully", "success");
    } else {
      console.error("Failed to finalize payment method");
      handleResponse("Failed to finalize payment method", "error");
    }
  };

  const getSelectedIntervalFromData = (data) => {
    for (const product of data.products) {
      if (product.subscribed && product.subscribedPriceId) {
        const subscribedPrice = Object.entries(product.prices).find(
          ([interval, price]) => price.id === product.subscribedPriceId
        );
        if (subscribedPrice) {
          return subscribedPrice[0];
        }
      }
    }
    return null;
  };

  useEffect(() => {
    if (orgId && stripeCustomerId) {
      fetchProductsAndSubscription();
    }
  }, [orgId, stripeCustomerId]);

  return (
    <div>
      <main className="py-6">
        <div className="px-4 sm:px-6 lg:px-6">
          {typeof backendData === "undefined" || isLoading ? (
            <p>Loading...</p>
          ) : (
            <>
              {paymentInfo && (
                <div className="mb-8">
                  <h2 className="text-gray-500 uppercase text-xsm font-medium pb-1 tracking-wide">
                    Payment information:
                  </h2>
                  {!showUpdatePaymentForm && (
                    <div className="flex">
                      <PaymentIcon
                        brand={paymentInfo.brand}
                        last4={paymentInfo.last4}
                        expMonth={paymentInfo.expMonth}
                        expYear={paymentInfo.expYear}
                      />

                      <LinkButton
                        type="function"
                        text="Update"
                        onClick={initiatePaymentUpdate}
                      />
                    </div>
                  )}
                </div>
              )}
              {showUpdatePaymentForm && clientSecret && (
                <div className="pb-4">
                  <Elements stripe={stripePromise}>
                    <UpdatePaymentForm
                      clientSecret={clientSecret}
                      onFinalize={finalizePaymentUpdate}
                      onCancel={() => setShowUpdatePaymentForm(false)}
                    />
                  </Elements>
                </div>
              )}

              <div>
                <SegmentedSwitch
                  optionOne={intervalOptions.optionOne}
                  optionTwo={intervalOptions.optionTwo}
                  onChange={handleIntervalChange}
                  selected={selectedInterval}
                />
                {planStatus === "trialing" && (
                  <div className="pt-6">
                    <Banner body={`Your trial ends on ${planTrialEndDate}`} />
                  </div>
                )}
                <div className="flex flex-row gap-8 pt-6 text-gray-600">
                  {backendData.products.map((item) => {
                    const selectedPriceDetails = item.prices[selectedInterval];

                    // Skip rendering if no price exists for the selected interval
                    if (!selectedPriceDetails) {
                      return null;
                    }

                    const selectedPrice = selectedPriceDetails.id;
                    const isSubscribedToSelectedPrice =
                      item.subscribed &&
                      item.subscribedPriceId === selectedPrice;

                    return (
                      <div
                        key={item.id}
                        className="flex flex-col w-1/4 bg-gray-50 p-4 rounded-lg"
                      >
                        <h3 className="text-sm">{item.name}</h3>
                        <div className="flex items-center space-x-2">
                          <span className="text-xlg font-extrabold text-black">
                            {selectedPriceDetails.amount === 0
                              ? "FREE"
                              : FormatPrice(
                                  selectedPriceDetails.amount,
                                  selectedPriceDetails.currency
                                )}
                          </span>
                          {isSubscribedToSelectedPrice && (
                            <StatusIndicator type="success" text="Subscribed" />
                          )}
                        </div>
                        <ul className="text-sm mt-2 mb-4">
                          {item.features.map((feature) => (
                            <li key={feature.name} className="pb-2">
                              {feature.name}
                            </li>
                          ))}
                        </ul>
                        <PrimaryButton
                          disabled={isSubscribedToSelectedPrice}
                          label={
                            isSubscribedToSelectedPrice
                              ? "Manage payment"
                              : "Subscribe"
                          }
                          onClick={() =>
                            handleChangeSubscription(selectedPrice)
                          }
                        />
                      </div>
                    );
                  })}
                </div>
                <Toast
                  show={toast.show}
                  message={toast.message}
                  type={toast.type}
                  onHide={hideToast}
                />
              </div>
            </>
          )}
        </div>
      </main>
    </div>
  );
}

export { Plan };
