import React from "react";
import { Routes, Route } from "react-router-dom";

import { AppLayout } from "../layouts/AppLayout";
// Importing components
import { Dashboard } from "../containers/dashboard";
import { Login } from "../containers/auth/login";
import { Logout } from "../containers/auth/logout";
import { Register } from "../containers/auth/register";
import { AuthCallback } from "../containers/auth/callback";
import { Unauthorized } from "../containers/auth/unauthorized";
import { Profile } from "../containers/profile/index";
import { Customers } from "../containers/customers";
import { Users } from "../containers/settings/users";
import { Plan } from "../containers/settings/plan";
import { LocationSettings } from "../containers/locations";
import { LocationSettingsDetails } from "../containers/locations/details";
import { Reporting } from "../containers/reporting";
import { Reports } from "../containers/reporting/reports";
import { Scheduling } from "../containers/scheduling";
import { Organization } from "../containers/settings/organization";
import ProtectedRoute from "./ProtectedRoute";

const RoutesWrapper = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/auth/login/" element={<Login />} />
      <Route path="/auth/logout/" element={<Logout />} />
      <Route path="/auth/callback/" element={<AuthCallback />} />
      <Route path="/auth/register/" element={<Register />} />
      <Route path="/auth/unauthorized/" element={<Unauthorized />} />

      {/* Protected Routes */}
      <Route
        path="/profile/"
        element={
          <ProtectedRoute requiredRole="admin:access">
            <AppLayout heading="Profile" current="profile">
              <Profile />
            </AppLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/"
        element={
          <ProtectedRoute requiredRole="admin:access">
            <AppLayout heading="Dashboard" current="dashboard">
              <Dashboard />
            </AppLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/scheduling/"
        element={
          <ProtectedRoute requiredRole="admin:access">
            <AppLayout heading="Scheduling" current="scheduling">
              <Scheduling />
            </AppLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/reporting/activities"
        element={
          <ProtectedRoute requiredRole="admin:access">
            <AppLayout heading="Activities" current="activities">
              <Reporting />
            </AppLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/reporting/reports/"
        element={
          <ProtectedRoute requiredRole="admin:access">
            <AppLayout heading="Reports" current="reports">
              <Reports />
            </AppLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/customers/"
        element={
          <ProtectedRoute requiredRole="admin:access">
            <AppLayout heading="Customers" current="customers">
              <Customers />
            </AppLayout>
          </ProtectedRoute>
        }
      />

      <Route
        path="/locations/"
        element={
          <ProtectedRoute requiredRole="admin:access">
            <AppLayout heading="Locations" current="locations">
              <LocationSettings />
            </AppLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/locations/:id"
        element={
          <ProtectedRoute requiredRole="admin:access">
            <AppLayout heading="Locations" current="locations">
              <LocationSettingsDetails />
            </AppLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings/users/"
        element={
          <ProtectedRoute requiredRole="admin:access">
            <AppLayout heading="Users" current="users">
              <Users />
            </AppLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings/organization/"
        element={
          <ProtectedRoute allowInitialization requiredRole="admin:access">
            <AppLayout heading="Organization" current="organization">
              <Organization />
            </AppLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings/plan/"
        element={
          <ProtectedRoute requiredRole="admin:access">
            <AppLayout heading="Plan" current="plan">
              <Plan />
            </AppLayout>
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings/billing/"
        element={
          <ProtectedRoute requiredRole="admin:access">
            <AppLayout heading="Billing" current="billing">
              <Users />
            </AppLayout>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export { RoutesWrapper };
