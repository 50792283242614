import React, { useContext, useState } from "react";
import { AuthContext } from "../contexts/AuthContext";
import Navigation from "./Navigation";
import {
  Bars3Icon,
  Cog6ToothIcon,
  ChevronDownIcon,
  ClipboardDocumentCheckIcon,
  MapPinIcon,
  RectangleGroupIcon,
  UserIcon,
  CogIcon,
} from "@heroicons/react/24/outline";

function AppLayout({ children, current, heading }) {
  const { authContext, logout } = useContext(AuthContext);
  const shouldShowNav = authContext.organization.initializationComplete === 1;

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const navOptions = [
    {
      name: "Dashboard",
      href: "/",
      icon: RectangleGroupIcon,
      current: current === "dashboard",
    },
    {
      name: "Reporting",
      icon: ClipboardDocumentCheckIcon,
      current: current === "reporting",
      children: [
        {
          name: "Activities",
          href: "/reporting/activities",
          current: current === "activities",
        },
        {
          name: "Reports",
          href: "/reporting/reports",
          current: current === "reporting-reports",
        },
      ],
    },
    {
      name: "Customers",
      href: "/customers",
      icon: UserIcon,
      current: current === "customers",
    },
    {
      name: "Locations",
      href: "/locations",
      icon: MapPinIcon,
      current: current === "locations",
    },
    {
      name: "Settings",
      icon: Cog6ToothIcon,
      current: current === "settings",
      separator: true,
      children: [
        {
          name: "Users",
          href: "/settings/users",
          current: current === "users",
        },
        {
          name: "Organization",
          href: "/settings/organization",
          current: current === "organization",
        },
        {
          name: "Plan",
          href: "/settings/plan",
          current: current === "plan",
        },
      ],
    },
  ];

  const userNavigation = [
    { name: "Your profile", type: "link", href: "/profile/" },
    { name: "Logout", type: "function", onClick: logout },
  ];

  if (!shouldShowNav) {
    return <div className="min-h-screen">{children}</div>;
  }

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex flex-1">
        <aside className="hidden lg:flex lg:flex-col lg:w-64">
          <Navigation.DesktopNavigation
            navOptions={navOptions}
            // orgName={authContext.organization.orgName}
          />
        </aside>

        <div className="flex flex-col flex-1">
          <header className="border-gray-100 bg-white">
            <Navigation.TopBar
              setSidebarOpen={setSidebarOpen}
              userNavigation={userNavigation}
              heading={heading}
            />
          </header>

          <main className="flex-1 overflow-auto flex flex-col">{children}</main>
        </div>
      </div>

      <Navigation.MobileNavigation
        navOptions={navOptions}
        orgName={authContext.organization.orgName}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
    </div>
  );
}

export { AppLayout };
